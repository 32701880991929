import { useCallback, useEffect, useMemo, useState } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import fromPairs from 'lodash/fromPairs';
import every from 'lodash/every';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import { ID } from '../../../types';

import { CheckedHashItem } from './useTableCheckable.types';

import { usePreviousValue } from '../usePreviousValue';

interface UseTableCheckableItem {
  id: ID;
}

interface UseTableCheckableProps<T extends UseTableCheckableItem> {
  items: T[];
  checkedIds?: ID[];
}

const EMPTY_HASH = {};

function useTableCheckable<T extends UseTableCheckableItem>({
  items,
  checkedIds = []
}: UseTableCheckableProps<T>) {
  const [checkedHash, setCheckedIds] = useState<CheckedHashItem>(
    isEmpty(checkedIds)
      ? EMPTY_HASH
      : fromPairs(checkedIds.map((id) => [id, true]))
  );

  const checkedAll = every(checkedHash) && size(checkedHash) === size(items);

  const checkedItems = useMemo<T[]>(
    () => filter(items, (item) => checkedHash[item.id]),
    [checkedHash, items]
  );

  const handleSetCheckedIds = useCallback(
    (itemId) =>
      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        [itemId]: !prevState[itemId]
      })),
    [setCheckedIds]
  );

  const handleCheckAll = useCallback(
    () =>
      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        ...fromPairs(items.map((item) => [item.id, !checkedAll]))
      })),
    [checkedAll, items, setCheckedIds]
  );

  const handleUncheckAll = useCallback(
    () => setCheckedIds({}),
    [setCheckedIds]
  );

  const prevCheckedIds = usePreviousValue(checkedIds);

  const prevItems = usePreviousValue(items);

  useEffect(() => {
    if (
      !isEqual(sortBy(prevCheckedIds), sortBy(checkedIds)) ||
      !isEqual(sortBy(prevItems), sortBy(items))
    ) {
      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        ...fromPairs(checkedIds.map((id) => [id, true]))
      }));
    }
  }, [prevCheckedIds, checkedIds, setCheckedIds, prevItems, items]);

  useEffect(() => {
    if (!isEqual(sortBy(prevItems), sortBy(items))) {
      setCheckedIds((prevState: { [key: string]: boolean }) => {
        const ids = items.map((item) => `${item.id}`);
        const prevStateIds = keys(prevState);

        return fromPairs(
          compact(
            prevStateIds.map((prevStateId: string) =>
              includes(ids, prevStateId)
                ? [prevStateId, prevState[prevStateId]]
                : null
            )
          )
        );
      });
    }
  }, [items, prevItems, setCheckedIds]);

  return {
    checkedHash,
    checkedItems,
    checkedAll,
    handleSetCheckedIds,
    handleCheckAll,
    handleUncheckAll
  };
}

export default useTableCheckable;
