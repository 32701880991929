import React, { ReactNode } from 'react';

interface IndexTablePageHeaderProps {
  children: ReactNode;
  actionButtons?: ReactNode;
}

function IndexTablePageHeader({
  children,
  actionButtons
}: IndexTablePageHeaderProps) {
  return (
    <div id="page-header-default" className="flex flex-1 px-4 justify-between">
      <div className="flex-1 flex">{children}</div>
      {actionButtons ? (
        <div className="ml-4 flex items-center lg:ml-6">{actionButtons}</div>
      ) : null}
    </div>
  );
}

export default IndexTablePageHeader;
