import React, { ReactNode, useState } from 'react';

import { ScrollToTop } from '../../../../helpers/scrolls/ScrollToTop';

interface MainScrollWrapperProps {
  withScrollToTop?: boolean;
  children: ReactNode;
}

function MainScrollWrapper({
  withScrollToTop,
  children
}: MainScrollWrapperProps) {
  const [mainElement, setMainElement] = useState<HTMLElement | null>(null);

  return (
    <main
      ref={setMainElement}
      className="flex-1 relative overflow-y-auto focus:outline-none z-0"
      tabIndex={-1}
    >
      {children}
      {withScrollToTop ? (
        <ScrollToTop scrollContainerElement={mainElement} />
      ) : null}
    </main>
  );
}

export default MainScrollWrapper;
