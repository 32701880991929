import { useCallback } from 'react';

import { FiltersPopoverTypesEnum } from '../../../../types';

import { useReactQueryState } from '../base/reactQuery/useReactQueryState';

const indexPageFiltersPopoverStateCacheKey = 'index-page-filters-popover-state';

const initialValue = FiltersPopoverTypesEnum.NONE;

function useIndexPageFiltersPopoverState() {
  const { value, clearValue, setValue } =
    useReactQueryState<FiltersPopoverTypesEnum>(
      indexPageFiltersPopoverStateCacheKey,
      initialValue
    );

  const openSavedFiltersPopover = useCallback<() => void>(
    () => setValue(FiltersPopoverTypesEnum.SAVED_FILTERS),
    [setValue]
  );

  const toggleActiveFiltersPopover = useCallback<() => void>(
    () =>
      setValue(
        value === FiltersPopoverTypesEnum.ACTIVE_FILTERS
          ? FiltersPopoverTypesEnum.NONE
          : FiltersPopoverTypesEnum.ACTIVE_FILTERS
      ),
    [setValue, value]
  );

  return {
    filtersPopoverType: value,
    setFiltersPopoverType: setValue,
    clearFiltersPopoverType: clearValue,
    toggleActiveFiltersPopover,
    openSavedFiltersPopover
  };
}

export default useIndexPageFiltersPopoverState;
