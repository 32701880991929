import React from 'react';

import { IndexTableCell } from '../IndexTableCell';

import { DateHelper } from '../../../../../helpers/DateHelper';

interface IndexTableCellDateProps {
  date: string;
}

function IndexTableCellDate({ date }: IndexTableCellDateProps) {
  return (
    <IndexTableCell>
      <DateHelper date={date} />
    </IndexTableCell>
  );
}

export default IndexTableCellDate;
