import React, { Fragment } from 'react';

import format from 'date-fns/format';

import { Locale } from '../../types';

import { dateFnsLocales } from '../../utils/dateFnsLocales';

interface DateHelperProps {
  date: Date | string | null;
  locale?: Locale;
  customFormat?: string;
}

function DateHelper({ date, locale = 'en', customFormat }: DateHelperProps) {
  const localeObj = dateFnsLocales.getLocale(locale);

  const formatOptions = localeObj ? { locale: localeObj } : {};

  return (
    <Fragment>
      {date
        ? format(new Date(date), customFormat || 'dd MMM yyyy', formatOptions)
        : null}
    </Fragment>
  );
}

export default DateHelper;
