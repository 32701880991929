import { useCallback } from 'react';
import { QueryKey, useQuery, useQueryClient } from 'react-query';
import isUndefined from 'lodash/isUndefined';

function useReactQueryState<ValueType>(
  cacheKey: QueryKey,
  initialValue: ValueType = null
) {
  const queryClient = useQueryClient();

  const cachedValue = queryClient.getQueryData<ValueType>(cacheKey);

  const { data } = useQuery<ValueType>(cacheKey, () =>
    isUndefined(cachedValue) ? initialValue : cachedValue
  );

  const clearValue = useCallback<() => void>(
    () => queryClient.setQueryData<ValueType>(cacheKey, null),
    [queryClient, cacheKey]
  );

  const setValue = useCallback<(value: ValueType | null) => void>(
    (value: ValueType | null) =>
      queryClient.setQueryData<ValueType>(cacheKey, value),
    [queryClient, cacheKey]
  );

  return {
    clearValue,
    setValue,
    value: data
  };
}

export default useReactQueryState;
